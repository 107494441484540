import React, { useState, useEffect } from 'react';
import { Table, Button, Container, Row, Col, Modal, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import ReactPaginate from 'react-paginate';
import './PekerjaanList.css'; // Custom CSS

const styles = StyleSheet.create({
    page: {
        padding: 20,
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: '#bfbfbf',
        marginTop: "20px",
    },
    tableRow: {
        flexDirection: "row",
    },
    tableHeaderCol: {
        width: "12.5%", // 8 columns
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: '#bfbfbf',
        backgroundColor: '#6495ED', // Light grey background for header
        padding: 5,
        color: "white"
    },
    tableHeaderText: {
        margin: "auto",
        fontSize: 10,
        fontWeight: 'bold', // Make header text bold
    },
    tableCol: {
        width: "12.5%", // Adjusted to fit 8 columns
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: '#bfbfbf',
        padding: 5,
    },
    tableCell: {
        margin: "auto",
        fontSize: 10,
    },
    image: {
        width: 50,
        height: 50,
    }
});

const PekerjaanList = () => {
    const [pekerjaanList, setPekerjaanList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalImage, setModalImage] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10; // Items per page

    useEffect(() => {
        axios.get('https://api.sipooppsu.my.id/pekerjaan/pekerjaan')
            .then(response => {
                const sortedData = response.data.sort((a, b) => b.id - a.id);
                setPekerjaanList(sortedData);
            })
            .catch(error => console.error(error));
    }, []);

    const filterPekerjaanByDate = () => {
        return pekerjaanList.filter(pekerjaan => {
            const pekerjaanDate = new Date(pekerjaan.tanggal);
            const start = new Date(startDate);
            const end = new Date(endDate);
            return pekerjaanDate >= start && pekerjaanDate <= end;
        });
    };

    const PekerjaanPDF = ({ filteredList, startDate, endDate }) => (
        <Document>
            <Page style={styles.page}>
                <Text style={{ fontSize: 10, marginBottom: 10, textAlign: 'center' }}>
                    LAPORAN HARIAN PELAKSANAAN PENANGAN PRASARANA DAN SARANA UMUM TINGKAT KELURAHAN
                </Text>

                <View style={{ marginTop: "20px" }}>
                    <Text style={{ fontSize: 10 }}>Kelurahan: Kampung Bali</Text>
                    <hr />
                    <Text style={{ fontSize: 10 }}>
                        Tanggal: {startDate && endDate ? `${new Intl.DateTimeFormat('id-ID').format(new Date(startDate))} - ${new Intl.DateTimeFormat('id-ID').format(new Date(endDate))}` : ''}
                    </Text>
                </View>

                <View style={styles.table}>
                    <View style={styles.tableRow} fixed>
                        <View style={styles.tableHeaderCol}><Text style={styles.tableHeaderText}>No</Text></View>
                        <View style={styles.tableHeaderCol}><Text style={styles.tableHeaderText}>Nama Petugas</Text></View>
                        <View style={styles.tableHeaderCol}><Text style={styles.tableHeaderText}>Nama Team</Text></View>
                        <View style={styles.tableHeaderCol}><Text style={styles.tableHeaderText}>Tanggal</Text></View>
                        <View style={styles.tableHeaderCol}><Text style={styles.tableHeaderText}>Kondisi Lapangan (Gambar)</Text></View>
                        <View style={styles.tableHeaderCol}><Text style={styles.tableHeaderText}>Keterangan Kondisi Lapangan</Text></View>
                        <View style={styles.tableHeaderCol}><Text style={styles.tableHeaderText}>Lokasi Pekerjaan</Text></View>
                        <View style={styles.tableHeaderCol}><Text style={styles.tableHeaderText}>Progres Pekerjaan (Gambar)</Text></View>
                        <View style={styles.tableHeaderCol}><Text style={styles.tableHeaderText}>Keterangan Pekerjaan</Text></View>
                    </View>

                    {filterPekerjaanByDate().map((pekerjaan, index) => (
                        <View style={styles.tableRow} key={index}>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>{index + 1}</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>{pekerjaan.nama_petugas}</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>{pekerjaan.nama_team}</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>{new Intl.DateTimeFormat('id-ID', { day: 'numeric', month: 'long', year: 'numeric' }).format(new Date(pekerjaan.tanggal))}</Text></View>
                            <View style={styles.tableCol}>
                                {pekerjaan.kondisi_lapangan_image && (
                                    <Image style={styles.image} src={`https://api.sipooppsu.my.id/uploads/${pekerjaan.kondisi_lapangan_image}`} />
                                )}
                            </View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>{pekerjaan.keterangan_kondisi_lapangan}</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>{pekerjaan.lokasi_pekerjaan}</Text></View>
                            <View style={styles.tableCol}>
                                {pekerjaan.progres_pekerjaan_image && (
                                    <Image style={styles.image} src={`https://api.sipooppsu.my.id/uploads/${pekerjaan.progres_pekerjaan_image}`} />
                                )}
                            </View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>{pekerjaan.keterangan_pekerjaan}</Text></View>
                        </View>
                    ))}
                </View>
            </Page>
        </Document>
    );

    // Pagination logic
    const offset = currentPage * itemsPerPage;
    const currentItems = pekerjaanList.slice(offset, offset + itemsPerPage);
    const pageCount = Math.ceil(pekerjaanList.length / itemsPerPage);

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    const handleDelete = (id) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            axios.delete(`https://api.sipooppsu.my.id/pekerjaan/pekerjaan${id}`)
                .then(() => setPekerjaanList(pekerjaanList.filter(item => item.id !== id)))
                .catch(error => console.error(error));
        }
    };

    return (
        <Container className="mt-5">
            <Row className="justify-content-md-center">
                <Col xs={12}>
                    <h2 className="text-center mb-4">Daftar Pekerjaan PPSU</h2>

                    <div className="d-flex justify-content-end mb-3">
                        <Form.Group controlId="startDate" className="me-2">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                        </Form.Group>
                        <Form.Group controlId="endDate">
                            <Form.Label>End Date</Form.Label>
                            <Form.Control type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                        </Form.Group>
                    </div>

                    <div className="d-flex justify-content-end mb-3">
                        <PDFDownloadLink
                            document={<PekerjaanPDF filteredList={filterPekerjaanByDate()} startDate={startDate} endDate={endDate} />}
                            fileName={`Laporan_PPSU_${startDate && endDate ? `${startDate}_${endDate}` : 'All'}.pdf`}
                        >
                            {({ loading }) => (
                                <Button variant="success" className="ms-2 mt-2">
                                    {loading ? 'Loading PDF...' : 'Download PDF'}
                                </Button>
                            )}
                        </PDFDownloadLink>
                    </div>

                    <div className="table-responsive">
                        <Table striped bordered hover className="table">
                            <thead className="table-header">
                                <tr>
                                    <th>No</th>
                                    <th>Nama Petugas</th>
                                    <th>Nama Team</th>
                                    <th>Tanggal</th>
                                    <th>Kondisi Lapangan (Gambar)</th>
                                    <th>Keterangan Kondisi Lapangan</th>
                                    <th>Lokasi Pekerjaan</th>
                                    <th>Progres Pekerjaan (Gambar)</th>
                                    <th>Keterangan Pekerjaan</th>
                                    <th>Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((pekerjaan, index) => (
                                    <tr key={pekerjaan.id}>
                                        <td>{offset + index + 1}</td> {/* Adjusted index for pagination */}
                                        <td>{pekerjaan.nama_petugas}</td>
                                        <td>{pekerjaan.nama_team}</td>
                                        <td>
                                            {new Intl.DateTimeFormat('id-ID', { day: 'numeric', month: 'long', year: 'numeric' }).format(new Date(pekerjaan.tanggal))}
                                        </td>
                                        <td>
                                            <img
                                                src={`https://api.sipooppsu.my.id/uploads/${pekerjaan.kondisi_lapangan_image}`}
                                                alt="Kondisi Lapangan"
                                                className="img-fluid clickable"
                                                style={{ maxWidth: '100px', cursor: 'pointer' }}
                                                onClick={() => setModalImage(`https://api.sipooppsu.my.id/uploads/${pekerjaan.kondisi_lapangan_image}`) && setShowModal(true)}
                                            />
                                        </td>
                                        <td>{pekerjaan.keterangan_kondisi_lapangan}</td>
                                        <td>{pekerjaan.lokasi_pekerjaan}</td>
                                        <td>
                                            <img
                                                src={`https://api.sipooppsu.my.id/uploads/${pekerjaan.progres_pekerjaan_image}`}
                                                alt="Progres Pekerjaan"
                                                className="img-fluid clickable"
                                                style={{ maxWidth: '100px', cursor: 'pointer' }}
                                                onClick={() => setModalImage(`https://api.sipooppsu.my.id/uploads/${pekerjaan.progres_pekerjaan_image}`) && setShowModal(true)}
                                            />
                                        </td>
                                        <td>{pekerjaan.keterangan_pekerjaan}</td>
                                        <td>
                                            <div className="d-flex flex-column flex-md-row">
                                                <Link to={`/edit/${pekerjaan.id}`}>
                                                    <Button variant="warning" className="me-md-2 mb-2 mb-md-0">
                                                        <i className="fas fa-edit"></i> Edit
                                                    </Button>
                                                </Link>
                                                <Button
                                                    variant="danger"
                                                    onClick={() => handleDelete(pekerjaan.id)}
                                                >
                                                    <i className="fas fa-trash"></i> Delete
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>

                    <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                    />
                </Col>
            </Row>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Body>
                    <img src={modalImage} alt="Kondisi Lapangan" className="img-fluid" />
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default PekerjaanList;
