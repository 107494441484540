import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import PekerjaanForm from './components/PekerjaanForm';
import PekerjaanList from './components/PekerjaanList';
import AdminList from './components/AdminListPekerjaan';

const App = () => {
  return (
    <Router>
      <Container fluid>
        {/* <h1 className="text-center my-4">CRUD Pekerjaan PPSU</h1> */}
        <Routes>
          <Route path="/" element={<AdminList />} />
          <Route path="/add" element={<PekerjaanForm />} />
          <Route path="/edit/:id" element={<PekerjaanForm />} />
          {/* <Route path="/admin" element={<AdminList />} /> */}
        </Routes>
      </Container>
    </Router>
  );
};

export default App;
