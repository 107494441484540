import React, { useState, useEffect } from 'react';
import { Table, Button, Container, Row, Col, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReactPaginate from 'react-paginate';  // Pagination library
import './PekerjaanList.css'; // Custom CSS

const PekerjaanList = () => {
    const [pekerjaanList, setPekerjaanList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage] = useState(10);  // Items per page
    const [showModal, setShowModal] = useState(false);
    const [modalImage, setModalImage] = useState('');

    useEffect(() => {
        axios.get('https://api.sipooppsu.my.id/pekerjaan/pekerjaan')
            .then(response => {
                // Sort by ID in descending order
                const sortedData = response.data.sort((a, b) => b.id - a.id);
                setPekerjaanList(sortedData);
            })
            .catch(error => console.error(error));
    }, []);

    const handleImageClick = (imageUrl) => {
        setModalImage(imageUrl);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalImage('');
    };

    // Pagination logic
    const pageCount = Math.ceil(pekerjaanList.length / itemsPerPage);
    const offset = currentPage * itemsPerPage;
    const currentItems = pekerjaanList.slice(offset, offset + itemsPerPage);

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    return (
        <Container className="mt-5">
            <h2 className="mb-4">Daftar Pekerjaan PPSU</h2>
            <Row className="justify-content-md-center">
                <Col xs={12}>
                    <div className="d-flex justify-content-end mb-3">
                        <Link to="/add">
                            <Button variant="primary" className="add-btn mt-2">
                                <i className="fas fa-plus-circle"></i> Tambah Pekerjaan
                            </Button>
                        </Link>
                    </div>

                    <div className="table-responsive">
                        <Table striped bordered hover className="table">
                            <thead className="table-header">
                                <tr>
                                    <th>No</th>
                                    <th>Nama Petugas</th>
                                    <th>Nama Team</th>
                                    <th>Tanggal</th>
                                    <th>Sumber Informasi</th>
                                    <th>Kondisi Lapangan (Gambar)</th>
                                    <th>Keterangan Kondisi Lapangan</th>
                                    <th>Lokasi Pekerjaan</th>
                                    <th>Progres Pekerjaan (Gambar)</th>
                                    <th>Keterangan Pekerjaan</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((pekerjaan, index) => (
                                    <tr key={pekerjaan.id}>
                                        <td style={{ textAlign: "center" }}>{index + 1 + (currentPage * itemsPerPage)}</td> {/* Display the correct index */}
                                        <td>{pekerjaan.nama_petugas}</td>
                                        <td>{pekerjaan.nama_team}</td>
                                        <td>
                                            {new Intl.DateTimeFormat('id-ID', { day: 'numeric', month: 'long', year: 'numeric' }).format(new Date(pekerjaan.tanggal))}
                                        </td>
                                        <td>{pekerjaan.sumber_informasi}</td>
                                        <td>
                                            <img
                                                src={`https://api.sipooppsu.my.id/uploads/${pekerjaan.kondisi_lapangan_image}`}
                                                alt="Kondisi Lapangan"
                                                className="img-fluid clickable"
                                                style={{ maxWidth: '100px', cursor: 'pointer' }}
                                                onClick={() => handleImageClick(`https://api.sipooppsu.my.id/uploads/${pekerjaan.kondisi_lapangan_image}`)}
                                            />
                                        </td>
                                        <td>{pekerjaan.keterangan_kondisi_lapangan}</td>
                                        <td>{pekerjaan.lokasi_pekerjaan}</td>
                                        <td>
                                            <img
                                                src={`https://api.sipooppsu.my.id/uploads/${pekerjaan.progres_pekerjaan_image}`}
                                                alt="Progres Pekerjaan"
                                                className="img-fluid clickable"
                                                style={{ maxWidth: '100px', cursor: 'pointer' }}
                                                onClick={() => handleImageClick(`https://api.sipooppsu.my.id/uploads/${pekerjaan.progres_pekerjaan_image}`)}
                                            />
                                        </td>
                                        <td>{pekerjaan.keterangan_pekerjaan}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>

                    {/* Pagination Component */}
                    <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                    />
                </Col>
            </Row>

            {/* Image Modal */}
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Image Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={modalImage} alt="Preview" className="img-fluid" />
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default PekerjaanList;
